.aws-btn {
  --slider-height-percentage: 60%;
  --slider-transition-duration: 700ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #4f8178;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #edf2f7;
  --control-bullet-active-color: #4f8178;
  --loader-bar-color: #4f8178;
  --loader-bar-height: 6px;
}

.aws-higher {
  height: 600px;
}

.awssld__bullets {
  bottom: 7px;
  z-index: 1000;
}

.awssld__bullets button {
  width: 10px;
  height: 10px;
}
